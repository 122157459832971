// css
import "./index.css";

// Component
import Header from '../../components/header'
import Footer from '../../components/footer'
import PrdB4CardItem from "./prdB4CardItem";

// img
import bg1 from '../../assets/img/product/prdBg1.png'
import accIcon1 from '../../assets/img/product/companyIcon1.svg'
import prdB2Content from '../../assets/img/product/TrashWeight.svg'
import prdb2line from '../../assets/img/product/prdB2Line.svg'
import bg3 from '../../assets/img/product/prdBg3.png'
import recycle from '../../assets/img/product/recycle.svg'
import fourassist from '../../assets/img/product/fourassist.svg'
import prd1 from '../../assets/img/product/prd1.png'
import prd2 from '../../assets/img/product/prd2.png'
import prd3 from '../../assets/img/product/prd3.png'
import prd4 from '../../assets/img/product/prd4.png'
import linebtn from '../../assets/img/product/linebtn.png'
import prdnewsimg2 from '../../assets/img/product/prdnewsimg2.svg'
import newsicon2 from '../../assets/img/product/newsicon2.svg'
import prdnewsimg3 from '../../assets/img/product/prdnewsimg3.jpg'
import newsicon3 from '../../assets/img/product/newsicon3.jpg'

function products(){
  document.title= "ACC-永續棚架"

  const b4CardList = [
    {
      order: "01",
      titleCh : "循環材料",
      titleEn : "MATERIAL",
      content: [
        "使用100%可回收再利用的環保材質",
        "堅固耐用，使用年限至少可達5-10年",
        "獨家高分子彈性與為微發泡技術，不含木粉木屑",
        "防水、防蟲、無毒、無重金屬、無甲醛等特性"
      ]
    },
    {
      order: "02",
      titleCh : "極簡設計",
      titleEn : "MINIMALIST",
      content: [
        '產品以極簡設計為出發，注重細節與美觀',
        '主色系可客製，黑、白、深淺木紋等色',
        '在各使用情景都能完美融入，增添氛圍與質感'
      ]
    },
    {
      order: "03",
      titleCh : "多功能性",
      titleEn : "FUNCTIONALITY",
      content: [
        "四款造型與尺寸規格，具備耐濕耐熱等多功能性",
        "讓產品可兼容室內戶外各種場景與情景應用",
        "結構化的設計也讓佈置擁有更多的可能與創意"
      ]
    },{
      order: "04",
      titleCh : "永續發展",
      titleEn : "SUSTAINABILITY",
      content: [
        "主材料使用廢棄塑膠製成，單一用料無雜質",
        '產品報廢後可還原成全新材料再製新品使用',
        '採用租賃、販售兩種模式同時進行',
        '租賃減少一次性浪費、販售負責終端回收處理' 
      ]
    }
  ]

  return(
    <>
      <Header></Header>
      <main>
        <section className="position-relative container-fluid px-0 overflow-hidden">
          <img src={bg1} alt="" className="w-100"/>
          <div className="prdBlock1-content position-absolute translate-middle-y">
            <div className="accIcon1">
              <img src={accIcon1} alt="" className="img-fluid" />
            </div>
            <h6 className="color_main">PRODUCT</h6>
            <h1>永續產品</h1>
            <p>為減少活動浪費而生,創造良好永續供應鍊</p>
          </div>
        </section>
        <section className="text-center py-5 position-relative">
          <h2>2020年</h2>
          <h2 className="mb-4">台灣一般廢棄物產出量再創新高</h2>
          <div className="prdB2Content mb-5 mx-auto">
            <img src={prdB2Content} alt="" className="img-fluid"/>
          </div>
          <p>
            當浪費遍佈生活周遭,改變可以從你我開始
          </p>
          <div className="prdb2line position-absolute translate-middle left-50">
            <img src={prdb2line} alt="" className="img-fluid"/>
          </div>
        </section>
        <section className="py-5" style={{background: `url(${bg3}) center center`, backgroundSize: `cover`}}>
          <div className="text-center">
            <div className="accIcon1 mx-auto">
              <img src={accIcon1} alt="" className="img-fluid" />
            </div>
            <h3 className="color_main">永續產品</h3>
            <p>正是為改變而生,創造良好永續供應鏈</p>
          </div>
          <div className="mx-auto recycleIcon">
            <img src={recycle} alt="" className="img-fuid"/>
          </div>
          <div className="mx-auto fourassist"> 
            <img src={fourassist} alt="" className="img-fuid"/>
          </div>
        </section>
        <section className="py-5">
          <div className="prdB4Card p-2 p-sm-3">
            {
              b4CardList.map((item)=>{
                const {order, titleCh, titleEn, content} = item
                return (
                <PrdB4CardItem 
                  key={order}
                  order={order}
                  titleCh={titleCh}
                  titleEn={titleEn}
                  content={content}
                />
                )
              })
            }
          </div>
        </section>
        <section className="text-center py-5">
          <h3 className="color_main mb-4">商品目錄</h3>
          <div className="fs-7 fw-light mb-3">
            點擊商品快速前往
          </div>
          <div className="d-flex 
          justify-content-start
          justify-content-sm-around align-items-center prdmenu-list mx-auto  mb-5">
            <div className="d-flex align-items-center">
              <a href="#prd1" className="px-3">
                <div className="fs-6 text-nowrap">X型極簡展架</div>
                <div className="fs-7 text-nowrap">X-Shape Rack</div>
              </a>
            </div>
            <div>|</div>
            <div className="d-flex align-items-center">
              <a href="#prd2" className="px-3">
                <div className="fs-6 text-nowrap">立方極簡展架(2M)</div>
                <div className="fs-7 text-nowrap">Cube Rack</div>
              </a>
            </div>
            <div>|</div>
            <div className="d-flex align-items-center">
              <a href="#prd3" className="px-3">
                <div className="fs-6 text-nowrap">立方極簡展架(3M)</div>
                <div className="fs-7 text-nowrap">Cube Rack</div>
              </a>
            </div>
            <div>|</div>
            <div className="d-flex align-items-center">
              <a href="#prd4" className="px-3">
                <div className="fs-6 text-nowrap">特規歐式白棚</div>
                <div className="fs-7 text-nowrap">Unique Tant</div>
              </a>
            </div>

          </div>

          <div className="prd-list container my-5">
            <div className="prd-item pt-5 d-flex justify-content-around flex-wrap flex-sm-nowrap" id="prd1">
              <div className="w-100 w-sm-20 mb-4 mb-sm-0 ">
                <div className="fs-5">X型極簡展架</div>
                <div className="fs-7">X-Shape Rack</div>
              </div>
              <div className="prd-img">
                <img src={prd1} alt="" className="object-fit-contain"/>
              </div>
              <div className="w-50 w-sm-30 text-start ps-3 px-sm-4">
                <div>
                  <div className="fs-7 text-nowrap">商品特色簡介</div>
                  <div className="fs-7 text-nowrap">ABOUT</div>
                  <p className="fw-light fs-7 mt-1">
                    佔地面積小，易搭建好搬運<br/>
                    木紋桌板，基本雨遮<br/>
                    適合更各種大小型式內外場地
                  </p>
                </div>
                <div className="">
                  <div className="fs-7 text-nowrap">寬/高/深</div>
                  <div className="fs-7 text-nowrap">WIDTH / HIGH / DEPTH</div>
                  <p className="fw-light fs-7 mt-1">
                    150 / 250 / 140 (cm)
                  </p>
                </div>

              </div>
            </div>
            <div className="prd-item pt-5 d-flex justify-content-around flex-wrap flex-sm-nowrap" id="prd2">
              <div className="w-100 w-sm-20 mb-4 mb-sm-0">
                <div className="fs-5">立方極簡展架(2M)</div>
                <div className="fs-7">Cube Rack</div>
              </div>
              <div className="prd-img">
                <img src={prd2} alt="" className="object-fit-contain"/>
              </div>
              <div className="w-50 w-sm-30 text-start ps-3 px-sm-4">
                <div>
                  <div className="fs-7 text-nowrap">商品特色簡介</div>
                  <div className="fs-7 text-nowrap">ABOUT</div>
                  <p className="fw-light fs-7 mt-1">
                    2米的立方體結構設計<br/>
                    頂篷可拆卸，兼具防雨功能<br/>
                    可作為棚架或展示結構兩種應用
                  </p>
                </div>
                <div className="">
                  <div className="fs-7 text-nowrap">
                    寬/前緣高/後緣高/深
                  </div>
                  <div className="fs-7">
                    WIDTH / HIGH LEADING EDGE / HIGH TRAILING EDGE / DEPTH
                  </div>
                  <p className="fw-light fs-7 mt-1">
                    200 / 230 / 210 / 300(cm)
                  </p>
                </div>

              </div>
            </div>
            <div className="prd-item pt-5 d-flex justify-content-around flex-wrap flex-sm-nowrap" id="prd3">
              <div className="w-100 w-sm-20 mb-4 mb-sm-0">
                <div className="fs-5">立方極簡展架(3M)</div>
                <div className="fs-7">Cube Rack</div>
              </div>
              <div className="prd-img">
                <img src={prd3} alt="" className="object-fit-contain"/>
              </div>
              <div className="w-50 w-sm-30 text-start ps-3 px-sm-4">
                <div>
                  <div className="fs-7 text-nowrap">商品特色簡介</div>
                  <div className="fs-7 text-nowrap">ABOUT</div>
                  <p className="fw-light fs-7 mt-1">
                    3米立方體使用空間與面積更大<br/>
                    頂篷可拆卸，兼具防雨功能<br/>
                    可作為棚架或展示結構兩種應用
                  </p>
                </div>
                <div className="">
                  <div className="fs-7 text-nowrap">
                    寬/前緣高/後緣高/深
                  </div>
                  <div className="fs-7">
                    WIDTH / HIGH LEADING EDGE / HIGH TRAILING EDGE / DEPTH
                  </div>
                  <p className="fw-light fs-7 mt-1">
                    300 / 230 / 210 / 300(cm)
                  </p>
                </div>

              </div>
            </div>
            <div className="prd-item pt-5 d-flex justify-content-around flex-wrap flex-sm-nowrap" id="prd4">
              <div className="w-100 w-sm-20 mb-4 mb-sm-0">
                <div className="fs-5">特規歐式白棚</div>
                <div className="fs-7">Unique Tant</div>
              </div>
              <div className="prd-img">
                <img src={prd4} alt="" className="object-fit-contain"/>
              </div>
              <div className="w-50 w-sm-30 text-start ps-3 px-sm-4">
                <div>
                  <div className="fs-7 text-nowrap">商品特色簡介</div>
                  <div className="fs-7 text-nowrap">ABOUT</div>
                  <p className="fw-light fs-7 mt-1">
                    獨家全白烤漆簡約設計(白柱腳)<br/>
                    金屬材質堅固耐用
                  </p>
                </div>
                <div className="">
                  <div className="fs-7 text-nowrap">
                    寬/頂高/柱高/深
                  </div>
                  <div className="fs-7">
                    WIDTH / TOP HEIGHT / COLUMN HEIGHT / DEPTH
                  </div>
                  <p className="fw-light fs-7 mt-1">
                    300 / 340 / 220 / 300(cm)
                  </p>
                </div>

              </div>
            </div>
          </div>
          <div className="text-center">
            <h2 className="color_main fw-bolder">如欲了解更多，歡迎您與我們聯繫</h2>
            <a href="https://lin.ee/LgxNp9v">
              <div className="mxw-200 mx-auto mt-3">
                <img src={linebtn} alt="" className="img-fluid"/>
              </div>
            </a>
          </div>
        </section>
        <section className="text-center py-5 container">
          <h3 className="color_main mb-4">媒體報導</h3>
          <div className="d-flex flex-wrap justify-content-around align-items-center">
            <div className="card text-start prdB6Card" >
              <img src={prdnewsimg2} className="card-img-top" alt="prdnewsimg2" />
              <div className="card-body">
                <div className="card-newsicon mxw-100 mb-3">
                  <img src={newsicon2} alt="newsicon2" className="img-fluid"/>
                </div>
                <h6 className="card-text">
                  全台首個循環材質打造的永續市集!<br/>
                  竹縣「冰雪聖誕市集」純白歐風美翻了!
                </h6>
                <a href="https://www.watchmedia01.com/dnews-20211209020254.html" className="stretched-link color_main me-auto fs-7">
                  更多
                </a>
              </div>
            </div>
            <div className="card text-start prdB6Card">
              <img src={prdnewsimg3} className="card-img-top" alt="prdnewsimg3" />
              <div className="card-body">
                <div className="card-newsicon mxw-100 mb-3">
                  <img src={newsicon3} alt="newsicon3" className="img-fluid"/>
                </div>
                <h6 className="card-text">
                  竹縣「東興圳光藝節」12/24登場<br/>
                  7大燈景藝術還飄人造雪
                </h6>
                <a href="https://tw.appledaily.com/life/20211208/FBZFJLNHCBEE3F7BD3H5AE3UTI/?utm_source=line&utm_medium=messenger&utm_campaign=twad_article_share&utm_content=share_link" className="stretched-link color_main me-auto fs-7">
                  更多
                </a>
              </div>
            </div>
            {/* <div className="card text-start prdB6Card">
              <img src={prdnewsimg2} className="card-img-top" alt="prdnewsimg2" />
              <div className="card-body">
                <div className="card-newsicon mxw-100 mb-3">
                  <img src={newsicon2} alt="newsicon2" className="img-fluid"/>
                </div>
                <h6 className="card-text">
                  全台首個循環材質打造的永續市集!<br/>
                  竹縣「冰雪聖誕市集」純白歐風美翻了!
                </h6>
                <a href="/" className="stretched-link color_main me-auto fs-7">
                  更多
                </a>
              </div>
            </div> */}
          </div>
        </section>

      </main>
      <Footer></Footer>
    </>
  )
}
export default products;
