// css
import "./index.css";

// Component
import Header from '../../components/header'
import Footer from '../../components/footer'
import Block1 from "./block1";
import Block2 from "./block2";
import Block3 from "./block3";
import Block4 from "./block4";
import Block5 from "./block5";
import Block6 from "./block6";
import Block7 from "./block7";

import shortCutLogo from '../../assets/img/header/accShortcutLogo.png'



function HomPage(){
  document.title = "ACC-首頁";
  return (
    <>
      <Header>
        <link rel="icon" href={shortCutLogo} />
      </Header>
      
      <main className="container-fluid p-0 home">
        <Block1/>
        <div className="bg-img-1 text-white product-intro">
          
          <Block2 />
          
          <Block3/>
          
          
        </div>
        <Block4/>
        <div className="bg-img-2 text-white service-intro">
          <Block5/>
          <Block6/>
        </div>
        <Block7/>
      </main>
      <Footer/>
      
    </>
  )
}
export default HomPage;