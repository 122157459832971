

function block2(props){

  return (
      <div className="block text-center" id="slider2">
         <div className="btn btn_main  fs-7 fw-light mb-3">Our Product</div>
      <h2 className="mb-4">
        永續棚架產品租賃販售
      </h2>
      <div className="mb-5">
        <span>極簡</span>
        <span>功能性</span>
        <span>永續</span>
      </div>
      <div className="color_gray mt-5">| 2022年初登場商品，首次活動即獲媒體好評報導 |</div>
      <div className="color_gray">| 為減少活動浪費而生，創造良好永續供應鏈 |</div>
      </div>
  )
}
export default block2;