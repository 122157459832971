import Block7Slider from "./block7Slider";

// img
import block7icon1 from '../../assets/img/HomePage/block7icon1.svg'
import block7icon2 from '../../assets/img/HomePage/block7icon2.svg'

const prdArr = [
  {
    rtl: true,
    data:[
      {
        id:1, 
        content: ["2021", "資安資戰"],
        imgName:"20211123_ACWSouth.jpg",
        href: '/'
      },
      {
        id:2,
        content: ["2022", "花燈迎天穿"],
        imgName:"202202_HsinChuTNY.jpg",
        href: '/'
      },
      {
        id:3,
        content: ["2022", "雲端學校"],
        imgName:"20220117_school+.jpg",
        href: '/'
      },
      {
        id:4,
        content: ["2022", "舞青盃街舞大賽"],
        imgName:"20220326_streetDance.jpg",
        href: '/'
      },
      {
        id:5,
        content: ["2022", "舞青童樂會"],
        imgName:"20220402_HsinChuCounty.jpg",
        href: '/'
      },
      {
        id:6,
        content: ["2022", "商業發展營"],
        imgName:"20220514_MOST.jpg",
        href: '/'
      },
      {
        id:38,
        content: ["2022", "X-talent"],
        imgName:"2022_X-talent.png",
        href: '/'
      },
      {
        id:39,
        content: ["2022", "創新創業激勵計畫"],
        imgName:"2022_FROM-IP-TO-IPO-PROGRAM1.png",
        href: '/'
      },
      {
        id:40,
        content: ["2022", "創新創業激勵計畫"],
        imgName:"2022_FROM-IP-TO-IPO-PROGRAM2.png",
        href: '/'
      }
    ]
    
  },
  {
    rtl: false,
    data:[
      {
        id:31,
        content: ["2021", "竹縣逐現"],
        imgName:"202111_HsinChuPretty.jpg",
        href: '/'
      },
      {
        id:32,
        content: ["2021", "西門PLAY樂購町"],
        imgName:"202111_XimenXmas.jpg",
        href: '/'
      },
      {
        id:33,
        content: ["2021", "童趣聖誕夜"],
        imgName:"202112_HsinChuXmas.jpg",
        href: '/'
      },
      {
        id:34,
        content: ["2021", "民生公共物聯網氣象節導覽"],
        imgName:"20210321_TWDICI.jpg",
        href: ''
      },
      {
        id:35,
        content: ["2021", "Spark計畫"],
        imgName:"20211001_Spark.jpg",
        href: '/'
      },
      {
        id:36,
        content: ["2021", "花旗年度風雲大賞"],
        imgName:"20211014_citi.jpg",
        href: '/'
      },
      {
        id:37,
        content: ["2021", "商業發展營"],
        imgName:"20211118_MOST.jpg",
        href: '/'
      },
      {
        id:38,
        content: ["2021", "今晚住竹縣.現領滿竹券"],
        imgName:"2021_hsinchu-check-in.png",
        href: '/'
      },
      {
        id:39,
        content: ["2021", "創新創業激勵計畫"],
        imgName:"2021_FROM-IP-TO-IPO-PROGRAM1.png",
        href: '/'
      },
      {
        id:40,
        content: ["2021", "創新創業激勵計畫"],
        imgName:"2021_FROM-IP-TO-IPO-PROGRAM2.png",
        href: '/'
      }
    ]
  },
  {
    rtl: true,
    data:[
      {
        id:71,
        content: ["2020", "創新創業激勵計畫"],
        imgName:"2020_FITI.jpg",
        href: '/'
      },
      {
        id:72,
        content: ["2020", "LEAP博士創新之星"],
        imgName:"2020_LEAP.jpg",
        href: '/'
      },
      // {
      //   id:16,
      //   content: "2020 資安攻防戰",
      //   imgName:"",
      //   href: '/'
      // },
      {
        id:73,
        content: ["2020", "來竹縣約會吧! 秋遊星夜季"],
        imgName:"202010_HsinChuDate.jpg",
        href: '/'
      },
      {
        id:74,
        content: ["2020", "西門PLAY樂購町"],
        imgName:"202012_Ximen.jpg",
        href: '/'
      },
      {
        id:75,
        content: ["2020 ", "科技大擂台賽後技術交流會"],
        imgName:"202012_FGC.jpg",
        href: '/'
      }
    ]
  }
]
function block7(){
  return (
    <div className="text-center py-5">
      <h2 className="mb-5">
        <span className="color_sec">活力</span>與<span className="color_sec">創造</span><br/>
        精準呈現客戶的期待
      </h2>
      <div className="container w-100 d-flex justify-content-around align-items-center mb-5">
        <div className="text-center d-flex flex-column flex-sm-row align-sm-items-center align-items-sm-end">
          <div>
            <img src={block7icon1} className="icon" alt="icon"/>
          </div>
          <div className="block7Disc d-flex align-items-end justify-content-center">
            團隊行銷經歷<h2 className="mb-0 color_sec mx-3">15</h2>年
          </div>
        </div>
        <div className="text-center d-flex flex-column flex-sm-row align-items-center align-items-sm-end">
          <div>
            <img src={block7icon2}className="icon" alt="icon" />
          </div>
          <div className="block7Disc d-flex align-items-end justify-content-center">
            行銷案例多達<h2 className="mb-0 color_sec mx-3">500</h2>項
          </div>
        </div>
      </div>

      <div className="w-100 text-center">
        {prdArr.map((item, i)=>{
          return (
          <Block7Slider key={i} prdlist={item.data} rtl={item.rtl}></Block7Slider>
          )
        })}
        <button className="btn btn_sec fs-6 fw-light mt-3 mt-xl-5">了解更多</button>
      </div>
    </div>
  )
}
export default block7;