import { NavLink } from 'react-router-dom';

function Menu(props){
  const {cn, en, href} = props
  return (
    <NavLink to={href}>
      {cn}<br/>
      <span className="fs-7">{en}</span>
    </NavLink>
  )
}
export default Menu;