import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import OwlCarouselRtl from 'react-owl-carousel-rtl';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

function block7Slider(props) {
  const { prdlist, rtl } = props;
  const option = {
    stagePadding: 75,
    items: 4,
    loop: true,
    margin: 8,
    autoplay: true,
    slidetransition: 'linear',
    autoplaySpeed: 2000,
    dots: 'false',
    lazyLoad: 'true',
    responsive: {
      0: {
        margin: 4,
        stagePadding: 20,
        items: 1,
      },
      500: {
        stagePadding: 50,
        items: 3,
      },
      1024: {
        margin: 8,
        items: 4,
      },
      1400: {
        items: 5,
      },
      2100: {
        items: 6,
      },
    },
  };
  const optionRtl = {
    items: 5,
    loop: true,
    margin: 8,
    autoplay: true,
    slidetransition: 'linear',
    autoplaySpeed: 2000,
    dots: 'false',
    lazyLoad: 'true',
    responsive: {
      0: {
        margin: 4,
        stagePadding: 20,
        items: 1,
      },
      500: {
        margin: 8,
        items: 3,
      },
      1024: {
        items: 5,
      },
      1400: {
        items: 6,
      },
      2100: {
        items: 7,
      },
    },
  };
  if (rtl) {
    return (
      <OwlCarousel className="owl-theme" rtl="true" {...option}>
        {prdlist.map((item) => {
          const { id, content, imgName, href } = item;
          return (
            <a href={href} key={id} >
              <div className="position-relative homeB7Slider">
                  <img className="prd-img" src={`../images/homePage/prdImg/${imgName}`} alt={imgName} />
                  <div className="position-absolute translate50 top-50 left-50 w-100 h-100 mask-dark"></div>
                  <h3 className="position-absolute translate50 top-50 left-50 w-100 text-white">
                    {
                      content.map((text, i)=>{
                        return <React.Fragment key={i}>{text}<br/></React.Fragment>
                      })
                    }
                  </h3>
              </div>
            </a>
          );
        })}
      </OwlCarousel>
    );
  } else {
    return (
      <OwlCarouselRtl
        className="owl-theme"
        rtlClass="owl-rtl"
        rtl="false"
        {...optionRtl}
        // autoplayHoverPause= {false}
      >
        {prdlist.map((item) => {
          const { id, content, imgName, href } = item;
          return (
            <a href={href}  key={id} >
              <div className="position-relative homeB7Slider">
                  <img className="prd-img" src={`../images/homePage/prdImg/${imgName}`} alt={imgName} />
                  <div className="position-absolute translate50 top-50 left-50 w-100 h-100 mask-dark"></div>
                  <h3 className="position-absolute translate50 top-50 left-50 w-100 text-white">
                    {
                      content.map((text,i)=>{
                        return <React.Fragment key={i}>{text}<br/></React.Fragment>
                      })
                    }
                  </h3>
              </div>
            </a>
          );
        })}
      </OwlCarouselRtl>
    );
  }
}
export default block7Slider;
