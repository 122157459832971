import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import GA4React from "ga-4-react";

class Doc extends React.Component {

  componentWillMount() {
    var storage = sessionStorage.getItem('referer');
    if(!storage){
      sessionStorage.setItem('referer', 'value');
      fetch(process.env.REACT_APP_URL + '/ag/referer/r', {
        method: "POST",
        headers: new Headers({
          'Content-Type': 'application/json'
        }),
        body: JSON.stringify({ "referer": document.referrer })
      }).catch(err => {
        console.error(err)
      })
    }

  }

  componentDidMount() {
    if (!window.GA_INITIALIZED) {
      const ga4react = new GA4React(process.env.REACT_APP_GA_TRACKING_ID)
      ga4react.initialize()
        .then((ga4) => { 
          ga4.pageview('path')
          ga4.gtag('event','pageview','path')
          window.GA_INITIALIZED = true
        })
        .catch( (err) => {
          console.log(err)
        })
    }
  }

  render() {
    return (
      <React.StrictMode>
        <App />
      </React.StrictMode>
    )
  }
}

ReactDOM.render(
  <Doc />,
  document.getElementById('root')
);
