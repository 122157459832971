function block5(){
  return (
    <div className="block" id="slider5">
      <div className="btn btn_sec fs-7 fw-light mb-3">Our Product</div>
      <h2 className="mb-4">全媒體行銷服務及內容製作</h2>
      <div className="mb-5">
        <span>專業</span>
        <span>快速</span>
        <span>精華</span>
      </div>
      <div className="color_gray mt-5">
        | 大型統籌專家整合製作 | 商業品牌行銷執行 |<br/>
        | 公關媒體議題操作 | 社群營銷經營維運 |<br/>
      </div>
      <div className="color_gray">
        | MARKETING | BRANDING |<br/>
        | PUBLIC RELATIONS | SOCIAL MEDIA |<br/>
      </div>
    </div>
  )
}
export default block5;