// img
import block1Bg from '../../assets/img/HomePage/bg-logo2.gif'
import block1Logo from '../../assets/img/HomePage/acclogo.svg'
import downIcon from '../../assets/img/HomePage/downIcon.svg'

function block1(){
  return (
    <div className="block">
          
      <div className="position-relative w-100">
        <div className="bg-img-0 position-absolute">
          <img src={block1Bg} alt="" id="bgLogo" />
        </div>
        <h1 className="mb-5 animate__animated animate__fadeInDown animate__delay-4s">
          創造更好的<span className="color_sec">行銷</span>環境
        </h1>
        <div className="mt-5 container d-flex w-100 justify-content-around align-items-center">
          <div className="text-center animate__animated animate__fadeInLeft animate__delay-5s">
            <div className="color_sec">在已知的領域創新</div>
            <h1 className="color_sec mt-5">
              行銷<br className='m-view'/>服務
            </h1>
            <div className="fs-7 pc-view">推廣全媒體行銷，整合性內容製作</div>
            <div className="fs-7 m-view">
              推廣全媒體行銷<br/>
              整合性內容製作
            </div>
            <a href="#slider5" className="btn btn_sec fs-7 mt-5 fw-light">了解更多</a>
          </div>
          <figure className="animate__animated animate__fadeIn animate__delay-5s w-30">
            <img src={block1Logo}className="w-75 w-sm-50" alt="logo" />
          </figure>
          <div className="text-center animate__animated animate__fadeInRight animate__delay-5s">
            <div className="color_main">在未知的領域開創</div>
            <h1 className="color_main mt-5">
              永續<br className='m-view'/>產品
            </h1>
            <div className="fs-7 pc-view">為減少浪費而生，創造永續供應鏈</div>
            <div className="fs-7 m-view">
              為減少浪費而生<br/>
              創造永續供應鏈
            </div>
            <a href="#slider2" className="btn btn_main fs-7 mt-5 fw-light">了解更多</a>
          </div>
        </div>

      </div>
      <div className="position-absolute bottom-0 animate__animated  animate__fadeInUp animate__delay-5s">
        <img src={downIcon} alt="" className="icon mb-5" />
      </div>
    </div>
  )
}

export default block1;