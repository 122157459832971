import { BrowserRouter,Routes,Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from './stores';
// import './assets/css/reset.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/common.css';


/* views */
import HomePage from './views/HomePage';
import Products from './views/Products';
import News1 from './views/News1';

const App = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <Routes>
          <Route path={"/*"} exact={false} element={<HomePage/>} />
          <Route path={"/products"} exact={false} element={<Products/>} />
          <Route path={"/news1"} exact={false} element={<News1/>} />
        </Routes>
      </BrowserRouter>
    </PersistGate>
  </Provider>
);

export default App;
