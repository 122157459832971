import { NavLink } from 'react-router-dom';

import logo from '../../assets/img/header/headerACClogo.svg'
import "./index.css";
import Menu from './menu';


function Header(){
  const menuArr = [
    {id:1, cn: "關於域創", en:"About ACC", href:'/'},
    {id:2,cn: "永續產品", en:"Our Product", href:'/products'},
    // {id:2,cn: "永續產品", en:"Our Product", href:'/'},
    {id:3,cn: "行銷服務", en:"Our Service", href:'/'},
    // {id:4,cn: "人才招募", en:"We Need You", href:'/'},
  ]

  return (
    <header className="d-flex bg-color_main text-white align-items-center py-3 px-4" id="pageTop">
      <NavLink to="/">
      <div className="d-flex header-logo align-items-center">
        <img src={logo} className="h-75" alt="" />
      </div>
      </NavLink>
      <div className="d-flex align-items-center">
        {
          menuArr.map((item)=>{
            const {id, cn, en, href} = item
            return (
              <Menu
                key={id}
                cn={cn}
                en={en}
                href={href}
              />
            )

          })
        }
      </div>
    </header>
  )
}
export default Header;