// css
import "./index.css";

// Component
import Header from '../../components/header'
import Footer from '../../components/footer'

// img
import Logo from '../../assets/img/news1/ACG_Newlogo_b.png'
import TIT from '../../assets/img/news1/2023TIT.png'
import Banner from '../../assets/img/news1/banner_s.png'

function News1(){
  document.title = "ACC-台灣人才永續聯盟";
  return (
    <>
      <Header/>
      
      <main className="container">
        <div className='acg-logo-wrap'>
          <img src={Logo} alt="ACG-Logo"/>
        </div>
        <div className="d-flex align-items-center mx-auto">
          <div className="w-50 text-center">
            <div className='tit-wrap mx-auto'>
              <img src={TIT} alt="TIT"/>
            </div>
          </div>
          <div className="h2 w-50" style={{lineHeight: 1.8}}>
            域創(ACC)正式宣布加入2023 TALENT, in Taiwan, 台灣人才永續行動聯盟
          </div>
        </div>
        <div className="my-4">
          <img src={Banner} alt="Banner"/>
        </div>
        <div className="content mb-5">
          <p>
            人才的培育與發展，一直是域創(ACC)重視的營運發展目標。因應ESG和DEI浪潮、全球企業對人才社會責任的看重，在此關鍵時刻，域創(ACC)正式宣布加入「TALENT, in Taiwan，台灣人才永續行動聯盟」！
          </p>
          <p>
            我們承諾推動【意義與價值】、【多元與包容】、【組織溝通】、【獎酬激勵】、【身心健康】、【人才成長】，透過實施彈性工時、各式活動讓同仁保持身心愉悅 ; 定期舉辦全公司對話，讓大家可以互相交流學習 ; 也藉由專案系統的導入，同仁的工作透明也便於互相支援 ; 設計符合個人的評量機制與個人成長規劃為每一同仁創造挑戰與成長機會 ; 並提供私密的員工暢言管道，可以讓同仁安心、自由提出的申訴或建議，域創(ACC) 將透過這些策略與措施，致力於打造一個具有競爭力且有吸引力的工作環境，在充滿變局的產業大浪中，保有人才永續的競爭力。
          </p>
          <p>
            接下來，我們將與《天下學習》、《Cheers快樂工作人》及200+聯盟夥伴持續倡議，共創人才培育的希望工程，為每一位工作人創造更好的未來。
          </p>
        </div>
      </main>
      <Footer/>
      
    </>
  )
}
export default News1;