// img
import block3Icon from '../../assets/img/HomePage/block3Icon.png'
import block3Item from '../../assets/img/HomePage/block3Item.svg'

function block3(){
  return (
    <div className="block text-center" id="slider3">
      <div className="section-icon">
        <img src={block3Icon} alt="" className="w-100" />
      </div>
      <h2 className="mb-4">永續纖維木 FPA+</h2>
      <div className="mb-5">
        以廢棄塑膠回收製成<br />
        材質永續無雜質且堅固耐用
      </div>
      <div className="container mt-5 content-img">
        <img src={block3Item} alt="" className="w-100" />
      </div>
    </div>
  )
}
export default block3;