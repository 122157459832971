import React from 'react';
function prdB4CardItem(props){
  const {order, titleCh, titleEn, content} = props

  return (
    <div className="d-flex prdB4CardItem  flex-column flex-sm-row  justify-content-between align-items-center py-4">
      <div className="text-center prdB4CardItem-title">
        <h1 className="color_main fw-bolder display-4">{order}</h1>
        <h6 className="fs-7 mb-0">{titleCh}</h6>
        <h6 className="fs-7">{titleEn}</h6>
      </div>
      <div className="card-content-divier"></div>
      <p className="mb-0 flex-grow-1 text-start ms-sm-5">
        {
          content.map((item, i)=>{
            return (
              <React.Fragment key={i}>
                {item} <br/>
              </React.Fragment>
            )
          })
        }
      </p>
    </div>
  )
}
export default prdB4CardItem;