// img
import block4prd1 from '../../assets/img/HomePage/block4prd1.svg'
import block4prd2 from '../../assets/img/HomePage/block4prd2.svg'
import block4prd3 from '../../assets/img/HomePage/block4prd3.svg'
import block4prd4 from '../../assets/img/HomePage/block4prd4.svg'


function block4(){
  return (
    <div className="block" id="slider4">
      <h2 className="mb-5">
        <span className="color_main">質感</span>與<span className="color_main">永續</span>可以並存<br />
        只要願意開始
      </h2>
      <div className="container my-3 d-flex justify-content-evenly align-items-start">
        <div className="mx-md-3 w-25 px-md-4">
          <figure>
            <img src={block4prd1} alt="家具圖" />
          </figure>
          <p>X-Shape Rack</p>
          <h4>X型</h4>
          <h4>極簡展架</h4>
          <div className="info-table">
            <div className="d-flex justify-content-between">
              <div>寬</div>
              <div>150</div>
            </div>
            <div className="d-flex justify-content-between">
              <div>高</div>
              <div>250</div>
            </div>
            <div className="d-flex justify-content-between">
              <div>深</div>
              <div>140</div>
            </div>
            <div className="d-flex justify-content-end">
              <div>cm</div>
            </div>
          </div>
        </div>
        <div className="mx-md-3 w-25 px-md-4">
          <figure>
            <img src={block4prd2} alt="家具圖" />
          </figure>
          <p>Cube Rack</p>
          <h4>兩米立方</h4>
          <h4>極簡棚架</h4>
          <div className="info-table">
            <div className="d-flex justify-content-between">
              <div>寬</div>
              <div>200</div>
            </div>
            <div className="d-flex justify-content-between">
              <div>前緣高</div>
              <div>230</div>
            </div>
            <div className="d-flex justify-content-between">
              <div>後援高</div>
              <div>210</div>
            </div>
            <div className="d-flex justify-content-between">
              <div>深</div>
              <div>300</div>
            </div>
            <div className="d-flex justify-content-end">
              <div>cm</div>
            </div>
          </div>
        </div>
        <div className="mx-md-3 w-25 px-md-4">
          <figure>
            <img src={block4prd3} alt="家具圖" />
          </figure>
          <p>Cube Rack</p>
          <h4>三米立方</h4>
          <h4>極簡棚架</h4>
          <div className="info-table">
            <div className="d-flex justify-content-between">
              <div>寬</div>
              <div>300</div>
            </div>
            <div className="d-flex justify-content-between">
              <div>前緣高</div>
              <div>230</div>
            </div>
            <div className="d-flex justify-content-between">
              <div>後援高</div>
              <div>210</div>
            </div>
            <div className="d-flex justify-content-between">
              <div>深</div>
              <div>300</div>
            </div>
            <div className="d-flex justify-content-end">
              <div>cm</div>
            </div>
          </div>
        </div>
        <div className="mx-md-3 w-25 px-md-4">
          <figure>
            <img src={block4prd4} alt="家具圖" />
          </figure>
          <p>Unique Tent</p>
          <h4>特規</h4>
          <h4>歐式白棚</h4>
          <div className="info-table">
            <div className="d-flex justify-content-between">
              <div>寬</div>
              <div>150</div>
            </div>
            <div className="d-flex justify-content-between">
              <div>高</div>
              <div>250</div>
            </div>
            <div className="d-flex justify-content-between">
              <div>深</div>
              <div>140</div>
            </div>
            <div className="d-flex justify-content-end">
              <div>cm</div>
            </div>
          </div>
        </div>
      </div>
      <a href="/products#pageTop" className="btn btn_main fs-6 fw-light">
        了解更多
      </a>
    </div>
  )
}
export default block4;