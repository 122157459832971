const arr=[
  {id:1, title:['大型統籌專案','規劃整合製作'], en:'MARKETING PROJECT', discript:['Planning/Coordinate','Prodution'], img:'block6icon1.png'},
  {id:2, title:['商業品牌','整體行銷執行'], en:'BRANDING PROJECT', discript:['Integrated Marketing','Communication'], img:'block6icon2.png'},
  {id:3, title:['公關媒體','議題操作曝光'], en:'PUBLIC RELATIONS', discript:['Press Release/Conference','Media buy'], img:'block6icon3.png'},
  {id:4, title:['社群行銷','內容經營維運'], en:'SOCIAL MeEDIA', discript:['Planning/Coordinate','Content/Design/maintaining'], img:'block6icon4.png'}
]
function block6(){
  return (
    <div className="block" id="slider6">
      <div className="container d-flex flex-wrap flex-md-nowrap justify-content-around align-items-center w-100">
        {arr.map((item)=>{
          const {id, title, en, discript, img} = item
          return (
          <div key={id} className="mb-4 w-50 w-sm-unset">
            <div className="mb-5 section-icon">
              <img className="w-100" src={`../images/homePage/${img}`} alt="icon" />
            </div>
            <h3 className="mb-1">{title[0]}</h3>
            <h3 className="mb-2">{title[1]}</h3>
            <p className="text-start mb-2">{en}</p>
            <p className="text-start">{discript[0]}<br/>{discript[1]}</p>
          </div>
          )
        })}
      </div>
    </div>
  )
}
export default block6;