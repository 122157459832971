import './index.css';
// img
// import footericonFB from '../../assets/img/footer/footericonFB.svg';
// import footericonLine from '../../assets/img/footer/footericonLine.svg';
import footericonMail from '../../assets/img/footer/footericonMail.svg';
import footericonACC from '../../assets/img/footer/footericonACC.svg';
import { Link } from 'react-router-dom';

function footer() {
  return (
    <footer className="container-fluid bg-color_main pt-4 px-1 p-md-5" style={{ height: '10rem' }}>
      <div className="d-flex align-items-center justify-content-between">
        <div className="text-white">
          <a href="/" className="text-white text-decoration-none">
            企業資訊
          </a>
          ｜
          <a href="/" className="text-white text-decoration-none">
            人才招募
          </a>
          ｜
          <Link to="/news1" className="text-white text-decoration-none">
            台灣人才永續行動聯盟
          </Link>
        </div>
        <div>
          {/* <a href="https://www.facebook.com/areacreate.tw/">
            <img src={footericonFB} alt="" className="icon" />
          </a>
          <a href="https://lin.ee/Dhx259w">
            <img src={footericonLine} alt="" className="icon" />
          </a> */}
          <a href="mailto: sayoyuan@areacreate.com">
            <img src={footericonMail} alt="" className="icon" />
          </a>
        </div>
      </div>
      <hr className="text-white" />
      <div
        className="d-flex flex-wrap align-items-center justify-content-center
      justify-content-sm-between"
      >
        <div className="text-white">域創股份有限公司 &copy; Area Create Co., Inc. All right reserved.</div>
        <div>
          <div className="header-logo">
            <img src={footericonACC} className="h-75" alt="" />
          </div>
        </div>
      </div>
    </footer>
  );
}
export default footer;
